<template>
  <div v-if="!this.$func.checkPermission('สถานะของระบบ')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card :title="'0'" subTitle="ระบบอัตโนมัติ" class="mb-2">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card :title="'0'" subTitle="ยอดเงิน ของระบบ" class="mb-2">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-money-coins text-danger"></i>
          </div>
        </stats-card>
      </div>
    </div> -->
    <div class="row mb-2">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(agentCredit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="เครดิต เอเย่นต์"
          class="mb-2"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>
      <div class="col col-md-8 col-lg-6">
        <!-- <TablePaymentActive></TablePaymentActive> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0 mr-2">บันทึกของแอดมิน</h4>
          </div>

          <div class="row">
            <div class="col-12 col-sm-4 col-md-3 col-lg-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationAdmin.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationAdmin.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQueryAdmin"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="medium"
                @click="getAdminLog()"
                :loading="loadingAdmin"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableDataAdmin"
                border
                v-loading="loadingAdmin"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อแอดมิน">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="คนสร้าง">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="400" label="รายละเอียด">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ประเภท">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วันที่">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"> </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromAdmin + 1 }} to {{ toAdmin }} of
                {{ totalAdmin }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationAdmin.currentPage"
              :per-page="paginationAdmin.perPage"
              :total="paginationAdmin.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <div class="d-flex">
              <h4 class="my-auto mr-2">บันทึกของเมมเบอร์</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 col-md-3 col-lg-2 d-flex mb-3">
              <el-select
                class="select-default w-100"
                v-model="paginationMember.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationMember.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQueryMember"
                aria-controls="datatables"
              />

              <el-button
                type="danger"
                size="medium"
                @click="getMemberLog()"
                :loading="loadingMember"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableDataMember"
                border
                v-loading="loadingMember"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อแอดมิน">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อลูกค้า">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="400" label="รายละเอียด">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="100" label="วันที่">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromMember + 1 }} to {{ toMember }} of
                {{ totalMember }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationMember.currentPage"
              :per-page="paginationMember.perPage"
              :total="paginationMember.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
  TimeSelect,
} from "element-ui";
import {
  StatsCard,
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import axios from "axios";
import TablePaymentActive from "../components/TablePaymentActive.vue";

export default {
  components: {
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    LPagination,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Dialog.name]: Dialog,
    [TableColumn.name]: TableColumn,
    // TablePaymentActive,
  },
  data() {
    return {
      loadingAdmin: true,
      loadingMember: true,
      modals: {
        modalHistoryWithdraw: false,
        modalAddCredit: false,
        centered: false,
        custom: false,
        confirm: false,
      },

      paginationMember: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      searchQueryMember: "",
      tableDataMember: [],

      paginationAdmin: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      searchQueryAdmin: "",
      tableDataAdmin: [],
      timeMemberLog: "",
      timeAdminLog: "",

      agentCredit: 0,

      paymentData: [],
    };
  },
  computed: {
    toMember() {
      let highBound = this.fromMember + this.paginationMember.perPage;
      if (this.totalMember < highBound) {
        highBound = this.totalMember;
      }
      return highBound;
    },
    fromMember() {
      this.getMemberLog();
      return (
        this.paginationMember.perPage * (this.paginationMember.currentPage - 1)
      );
    },
    totalMember() {
      return this.paginationMember.total;
    },

    toAdmin() {
      let highBound = this.fromAdmin + this.paginationAdmin.perPage;
      if (this.totalAdmin < highBound) {
        highBound = this.totalAdmin;
      }
      return highBound;
    },
    fromAdmin() {
      this.getAdminLog();
      return (
        this.paginationAdmin.perPage * (this.paginationAdmin.currentPage - 1)
      );
    },
    totalAdmin() {
      return this.paginationAdmin.total;
    },
  },
  mounted() {
    this.getCreditAgent();
  },
  methods: {
    async countDeposit() {
      let data = JSON.stringify({
        start: this.startDateCount,
        end: this.endDateCount,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/countdeposit`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          const data = res.data.res_result;
          this.deposit = data.deposit;
          this.deposit_admin = data.deposit_admin;
          this.deposit_auto = data.deposit_auto;
          this.deposit_bonus = data.deposit_bonus;
        })
        .catch((error) => {});
    },
    async getMemberLog() {
      this.loadingMember = true;
      let offset =
        this.paginationMember.perPage * (this.paginationMember.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/member_log?search=${this.searchQueryMember}&sort&order=desc&offset=${offset}&limit=${this.paginationMember.perPage}&action&start_date=&end_date=`,
        headers: {
          Authorization: this.$cookies.get("token"),
        },
        timeout: 2000,
      };

      clearTimeout(this.timeMemberLog);

      this.timeMemberLog = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loadingMember = false;
            const data = res.data.res_result;
            this.tableDataMember = data.rows;
            this.paginationMember.total = data.total;
          })
          .catch((error) => {
            this.loadingMember = false;
          });
      }, 200);
    },
    async getAdminLog() {
      this.loadingAdmin = true;
      let offset =
        this.paginationAdmin.perPage * (this.paginationAdmin.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/admin_log?search=${this.searchQueryAdmin}&sort&order=desc&offset=${offset}&limit=${this.paginationAdmin.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 2000,
      };

      clearTimeout(this.timeAdminLog);

      this.timeAdminLog = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loadingAdmin = false;
            const data = res.data.res_result;
            this.tableDataAdmin = data.rows;
            this.paginationAdmin.total = data.total;
          })
          .catch((error) => {
            this.loadingAdmin = false;
          });
      }, 1000);
    },
    async getCreditAgent() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/creditagent`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 2000,
      };

      await axios.request(config).then((res) => {
        this.loadingAdmin = false;
        const data = res.data.res_result.data;
        this.agentCredit = data.balance;
      });
    },
  },
};
</script>
<style></style>
